import React from 'react';
import PropTypes from 'prop-types';

import List from './List';
import Panel from './core/Panel';
import TextBlock from './TextBlock';

const ResultsPanel = ({
  items,
  text,
  backgroundImage,
  title
}) => {

  return (
    <Panel
      className='results'
      color='dark'
      title={ title }
      backgroundImage={ backgroundImage }
    >
      <div className='results__body'>
        <TextBlock>{text}</TextBlock>
        <List items={ items } />
      </div>
    </Panel>
  );
};

ResultsPanel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.string,
      textContent: PropTypes.string.isRequired,
      className: PropTypes.string,
    })
  ),
  text: PropTypes.string,
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
};

export default ResultsPanel;