import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({
  imgSrc = '',
  textContent,
  className = '',
}) => {
  let constructedClassName = (className || 'list__item')

  return (
    <p className={ constructedClassName }>
      { imgSrc &&
          <img className='list__image'
            src={ imgSrc }
            alt=''
          />
      }

      { textContent }
    </p>
  );
};

ListItem.propTypes = {
  imgSrc: PropTypes.string,
  textContent: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default ListItem;

