import React from "react"
import PropTypes from "prop-types"
import Panel from "./core/Panel"
import TextBlock from "./TextBlock"
import DeviceGallery from "./DeviceGallery"

const Showcase = ({ body, devices, backgroundImage }) => {
  return (
    <Panel
      color="purple"
      backgroundImage={backgroundImage}
      className="showcase"
    >
      <div className="showcase__content">
        <TextBlock title="Our work">
          {body}
        </TextBlock>
        <DeviceGallery devices={devices} />
      </div>
    </Panel>
  )
}

Showcase.propTypes = {
  devices: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      src: PropTypes.shape({
        publicURL: PropTypes.string,
      }),
      title: PropTypes.string,
    }),
  }).isRequired,
  backgroundImage: PropTypes.string,
}

export default Showcase
