import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Panel from "./core/Panel"
import TextBlock from "./TextBlock"
import Icon from "../components/core/Icon"
import { MDXRenderer } from "gatsby-plugin-mdx"

const ClientInfo = ({
  logoURL,
  logoBackground,
  logoScale,
  clientName,
  whatWeDid,
  brief,
  websiteURL,
  techTags,
}) => {
  const technologyIcons = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/technologies/" } }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                text
                path
                icon {
                  publicURL
                }
              }
              body
            }
          }
        }
      }
    `
  )
  const icons = technologyIcons.allMdx.edges

  // compare icons query array and techtags array to pull out relevant icons
  const techIcons = []
  techTags &&
    techTags.map(tag => {
      return icons.forEach(icon => {
        icon.node.frontmatter.text === tag && techIcons.push(icon)
      })
    })

  return (
    <Panel>
      <header>
        <img
          src={logoURL}
          alt={`${clientName} logo`}
          className={`client-info__logo${ logoBackground ? `--${logoBackground}` : ''}`}
          style={ { height: `${ 2.3 * (logoScale ?? 1) }rem` } }
        />
      </header>

      <main className="client-info">
        <div className="client-info__left">
          <section className="client-info__section">
            <h2 className="client-info__title">Client</h2>
            <p className="client-info__client-name">{ clientName }</p>
          </section>

          <section className="client-info__section">
            <h3 className="client-info__subtitle">What we did</h3>

            <MDXRenderer>{ whatWeDid }</MDXRenderer>
          </section>

          <section className="client-info__section">
            <h3 className="client-info__subtitle">Visit the website</h3>
            <a href={ websiteURL }>{ websiteURL }</a>
          </section>
        </div>

        <div className="client-info__right">
          <h2 className="client-info__title">The Brief</h2>
          <TextBlock>{brief}</TextBlock>
        </div>
        { techTags && techTags.length >= 1 && (
          <section className="client-info__technologies">
            <h3 className="client-info__subtitle">Technologies</h3>
            <div className="client-info__tech">
              { techIcons.map(({ node }) => (
                <Icon
                  link={ node.frontmatter.path }
                  className="technologies__icon"
                  key={ node.id }
                  { ...node }
                />
              )) }
            </div>
          </section>
        ) }
      </main>
    </Panel>
  )
}

ClientInfo.propTypes = {
  logoURL: PropTypes.string,
  logoScale: PropTypes.number,
  clientName: PropTypes.string,
  whatWeDid: PropTypes.string,
  brief: PropTypes.string,
  websiteURL: PropTypes.string,
}

export default ClientInfo
