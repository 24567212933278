import React from 'react';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

const List = ({ items = [] }) => {
  return !items.length ? null : (
    <ul className='list'>
      { items.map((item, i) => (
        <li
          className='list__li'
          key={ i }
        >
          <ListItem { ...item } />
        </li>
      )) }
    </ul>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imgSrc: PropTypes.string,
      textContent: PropTypes.string.isRequired,
      className: PropTypes.string,
    })
  ),
}

export default List;