import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO"
import HeroCaseStudy from "../components/HeroCaseStudy"
import ClientInfo from "../components/ClientInfo"
import FeaturePanel from "../components/FeaturePanel"
import ResultsPanel from "../components/ResultsPanel"
import Showcase from "../components/Showcase"
import ContactForm from "../components/ContactForm"

import safeGetImage from "../utils/safeGetImage"

const CaseStudy = ({ data }) => {
  const {
    mdx: { frontmatter, body },
  } = data

  const {
    client,
    title,
    shortDescription,
    heroBackgroundImage,
    techTags,

    logoURL,
    logoBackground,
    logoScale,
    whatWeDid,
    brief,
    websiteURL,

    imageDesktop,
    imageTablet,
    imageMobile,
    showcaseBackgroundImage,

    featurePanel,
    featureTitle,
    featureText,
    featureImage,
    featureImageAlt,

    resultsTitle,
    resultsText,
    results,
    resultsBackgroundImage,
  } = frontmatter

  const resultItems = results.map(item => {
    const imgSrc = safeGetImage(item.imgSrc)

    return {
      ...item,
      ...(imgSrc ? { imgSrc } : {}),
    }
  })

  const devices = {
    desktop: {
      src: imageDesktop,
      title: client,
    },
    tablet: {
      src: imageTablet,
      title: client,
    },
    mobile: {
      src: imageMobile,
      title: client,
    },
  }

  return (
    <>
      <SEO title={ client } />
      <HeroCaseStudy
        pageTitle={ client }
        title={ title }
        text={ shortDescription }
        backgroundImage={ `url(${ safeGetImage(heroBackgroundImage) })` }
      />

      <ClientInfo
        clientName={ client }
        logoURL={ safeGetImage(logoURL) }
        logoScale={ logoScale }
        logoBackground={ logoBackground }
        whatWeDid={ whatWeDid }
        brief={ brief }
        websiteURL={ websiteURL }
        techTags={ techTags }
      />

      <Showcase
        body={ body }
        devices={ devices }
        backgroundImage={ `url(${ safeGetImage(showcaseBackgroundImage) })` }
      />

      { featurePanel && (
        <FeaturePanel
          text={ featureText }
          title={ featureTitle }
          image={ featureImage }
          imageAlt={ featureImageAlt }
        />
      ) }

      <ResultsPanel
        items={ resultItems }
        text={ resultsText }
        title={ resultsTitle }
        backgroundImage={ `url(${ safeGetImage(resultsBackgroundImage) })` }
      />

      <ContactForm />
    </>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query CaseStudyByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        client
        title
        shortDescription
        websiteURL
        heroBackgroundImage
        techTags

        whatWeDid
        brief
        logoURL
        logoBackground
        logoScale

        imageDesktop {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageTablet {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageMobile {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        showcaseBackgroundImage

        featurePanel
        featureTitle
        featureText
        featureImage {
          publicURL
        }
        featureImageAlt

        resultsTitle
        resultsText
        results {
          imgSrc
          textContent
        }
        resultsBackgroundImage
      }
      body
    }
  }
`
