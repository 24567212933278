import React from "react"
import PropTypes from "prop-types"

import Panel from "./core/Panel"
import TextBlock from "./TextBlock"

const FeaturePanel = ({ title, text, image, imageAlt }) => {
  return (
    
    <Panel className="feature">
      <TextBlock className="feature__text" title={title}>{text}</TextBlock>
      <figure className="feature__image">
        <img alt={imageAlt} src={image.publicURL} />
      </figure>
    </Panel>
  )
}

FeaturePanel.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.shape({
    publicURL: PropTypes.string,
  }),
  imageAlt: PropTypes.string,
}

export default FeaturePanel
