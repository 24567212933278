import React from "react"
import PropTypes from "prop-types"

import Panel from "./core/Panel"
import TextBlock from "./TextBlock"

const HeroCaseStudy = ({
  pageTitle,
  title,
  icon,
  text,
  backgroundImage,
  className,
}) => {
  const textData = {
    pageTitle,
    title,
    text,
    icon,
    backgroundImage,
    className: "text-card--hero",
  }
  return (
    <Panel
      backgroundImage={backgroundImage}
      className={`single-case-study-hero ${className ? className : ""}`}
    >
      <TextBlock {...textData}>{text}</TextBlock>
    </Panel>
  )
}

HeroCaseStudy.propTypes = {
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  backgroundImage: PropTypes.string,
}

export default HeroCaseStudy
